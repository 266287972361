import "./App.css";
import { Routes, Route } from "react-router-dom";
import { RequireAuth } from "./routes/RequireAuth";
import { FeedsPage } from "./pages/FeedsPage";
import { LoginPage } from "./pages/LoginPage";
import { SignupPage } from "./pages/SignupPage";
import { OrganizationsPage } from "./pages/OrganizationsPage";
import { OrganizationPage } from "./pages/OrganizationPage";
import { AuthProvider } from "./hooks/useAuth";
import { ChakraProvider } from "@chakra-ui/react";
import { Modals } from "./features/Modals";

export default function App() {
  return (
    <AuthProvider>
      <ChakraProvider>
        <div className="app max-w-screen-2xl mx-auto">
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <FeedsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/organizations"
              element={
                <RequireAuth>
                  <OrganizationsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:id"
              element={
                <RequireAuth>
                  <OrganizationPage />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
          </Routes>
          <Modals />
        </div>
      </ChakraProvider>
    </AuthProvider>
  );
}
