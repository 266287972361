import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { getHttpClient } from "../network";
import { UseQueryKey } from "../reactQuery.const";
import { ProfileDAO } from "../types/response.type";

const httpClient = getHttpClient();

interface UserPayload {
  email: string;
  password: string;
}

interface PreferencePayload {
  activeOrgId?: string;
}

interface IAuthContext {
  isLoading: boolean;
  user: ProfileDAO;
  login: (payload: UserPayload) => Promise<void>;
  logout: () => Promise<void>;
  signup: (payload: UserPayload) => Promise<void>;
  updatePreferences: (payload: PreferencePayload) => Promise<void>;
}

const AuthContext = React.createContext<IAuthContext>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null);

  const profileQuery = useQuery(
    [UseQueryKey.profile],
    () => {
      return httpClient.get("/profile").then((res) => res.data);
    },
    {
      retry: 0,
    }
  );
  const finalUser = user ?? profileQuery.data;

  const login = async (payload: UserPayload) => {
    const res = await httpClient
      .post("/auth/login", payload)
      .then((res) => res.data);
    setUser(res);
  };

  const logout = async () => {
    await httpClient.get("/auth/logout");
  };

  const signup = async (payload: UserPayload) => {
    const res = await httpClient
      .post("/auth/signup", payload)
      .then((res) => res.data);
    return res;
  };

  const updatePreferences = useCallback(
    async (payload: PreferencePayload) => {
      const lastUser = finalUser;
      setUser({
        ...finalUser,
        preferences: {
          ...finalUser.preferences,
          ...payload,
        },
      });
      const res = await httpClient
        .post("/profile/preferences", payload)
        .then((res) => res.data)
        .catch(() => {
          setUser(lastUser);
        });
      return res;
    },
    [finalUser]
  );
  const value = useMemo(() => {
    return {
      isLoading: profileQuery.isLoading,
      user: finalUser,
      login,
      logout,
      signup,
      updatePreferences,
    };
  }, [profileQuery.isLoading, finalUser, updatePreferences]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
