import { useState } from "react";
import { Form, Field } from "forming";
import { useMutation } from "react-query";
import { getHttpClient } from "../network";
import { useAuth } from "../hooks/useAuth";

const httpClient = getHttpClient();

const FORM_INITIAL_VALUES = { name: "", symbol: "" };
const OrgCreationForm = () => {
  const [isOrgFormVisible, setIsOrgFormVisible] = useState(false);
  const orgMut = useMutation((newOrg) => {
    return httpClient.post("/organizations", newOrg);
  });
  return (
    <div className="flex-auto flex justify-center items-center">
      {!isOrgFormVisible ? (
        <div className="text-center">
          <div>Seems you don't have an org yet, let's create one first</div>
          <button
            className="btn btn-primary mt-4"
            onClick={() => {
              setIsOrgFormVisible(true);
            }}
          >
            Create an organization
          </button>
        </div>
      ) : (
        <div>
          <Form
            initialValues={FORM_INITIAL_VALUES}
            onSubmit={({ values }) => {
              // Why type doesn't work?
              orgMut.mutate(values as any);
            }}
          >
            <Field name="name">
              {({ value, onChange }) => {
                return (
                  <div className="form-control w-full max-w-xs">
                    <label className="label">
                      <span className="label-text">Org Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="input input-bordered w-full max-w-xs"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                );
              }}
            </Field>
            <Field name="symbol">
              {({ value, onChange }) => {
                return (
                  <div className="form-control w-full max-w-xs">
                    <label className="label">
                      <span className="label-text">Org Symbol</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="input input-bordered w-full max-w-xs"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                );
              }}
            </Field>
            <button type="submit" className="btn btn-primary mt-8">
              Create
            </button>
          </Form>
        </div>
      )}
    </div>
  );
};

export function Main() {
  const auth = useAuth();
  const orgs = auth.user.orgs;
  return orgs.length > 0 ? <div>hi</div> : <OrgCreationForm />;
}
