import { Button } from "@chakra-ui/react";
import { useAuth } from "../hooks/useAuth";
import { GroupDao, ProfileDAO } from "../types/response.type";
import { useCreateGroupModalStore } from "./CreateGroupModal/store";

function getCurrentGroups(user: ProfileDAO | undefined) {
  if (!user) {
    return [];
  }
  const activeOrgId = user.preferences?.activeOrgId;
  return user.groups
    .filter((group) => group.group.orgId === activeOrgId)
    .map((group) => group.group);
}

const GroupList = ({ groups }: { groups: GroupDao[] }) => {
  return (
    <ul>
      {groups.map((group) => {
        return (
          <li key={group.id} className="cursor-pointer my-2">
            <div>
              <span className="mr-2">#</span>
              <span>{group.name}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
export function Sidebar() {
  const auth = useAuth();
  const createGroupModalStore = useCreateGroupModalStore();
  const groups = getCurrentGroups(auth.user);

  return (
    <div className="w-60">
      <Button
        onClick={() => {
          createGroupModalStore.toggle(true);
        }}
      >
        Add new group
      </Button>
      <GroupList groups={groups} />
    </div>
  );
}
