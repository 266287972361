import create from "zustand";

interface CreateGroupModalState {
  isOpen: boolean;
  toggle: (isOpen: boolean) => void;
}
export const useCreateGroupModalStore = create<CreateGroupModalState>(
  (set) => ({
    isOpen: false,
    toggle: (isOpen: boolean) =>
      set((state) => ({
        isOpen,
      })),
  })
);
