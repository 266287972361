import { Header } from "../features/Header";
import { Sidebar } from "../features/Sidebar";
import { OrganizationMain } from "./organizations/OrganizationMain";

export function OrganizationPage() {
  return (
    <div>
      <Header />
      <div className="flex h-[calc(100vh-66px)]">
        <Sidebar />
        <OrganizationMain />
      </div>
    </div>
  );
}
