import { useQuery } from "react-query";
import { UseQueryKey } from "../../reactQuery.const";
import { getHttpClient } from "../../network";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { OrganizationsDAO } from "../../types/response.type";
import { Link } from "react-router-dom";

const httpClient = getHttpClient();
export function OrganizationsMain() {
  const organizations = useQuery<OrganizationsDAO[]>(
    [UseQueryKey.organizations],
    () => {
      return httpClient.get("/organizations").then((res) => res.data);
    }
  );
  return (
    <div>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Symbol</Th>
              <Th>Created AT</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(organizations.data ?? []).map((org) => {
              return (
                <Tr key={org.id}>
                  <Td>{org.name}</Td>
                  <Td>{org.symbol}</Td>
                  <Td>{org.createdAt}</Td>
                  <Td>
                    <Link to={`/organizations/${org.symbol}`}>View</Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}
