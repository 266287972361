import axios from "axios";
import { getApiHost } from "./id";
export function getUrlBase() {
  return `${getApiHost()}:3001`;
}

export function getHttpClient() {
  const client = axios.create({
    baseURL: getUrlBase(),
    timeout: 30000, // 30 seconds
    withCredentials: true,
  });
  return client;
}
