import { Header } from "../features/Header";
import { Sidebar } from "../features/Sidebar";
import { Form, Field } from "forming";
import { useAuth } from "../hooks/useAuth";

const Signup = () => {
  const auth = useAuth();
  return (
    <div>
      <Form
        initialValues={{ email: "", password: "" }}
        onSubmit={({ values }) => {
          auth.signup({
            email: values.email,
            password: values.password,
          });
        }}
      >
        <Field name="email">
          {({ value, onChange }) => {
            return (
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  value={value}
                  onChange={onChange}
                />
              </div>
            );
          }}
        </Field>
        <Field name="password">
          {({ value, onChange }) => {
            return (
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <input
                  type="password"
                  placeholder="Type here"
                  className="input input-bordered w-full max-w-xs"
                  value={value}
                  onChange={onChange}
                />
              </div>
            );
          }}
        </Field>
        <button type="submit" className="btn btn-primary mt-8">
          Sign up
        </button>
      </Form>
    </div>
  );
};

export const SignupPage = () => {
  return (
    <div>
      <Header />
      <div className="flex h-[calc(100vh-66px)]">
        <Sidebar />
        <Signup />
      </div>
    </div>
  );
};
