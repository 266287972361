import { useQuery } from "react-query";
import { UseQueryKey } from "../../reactQuery.const";
import { getHttpClient } from "../../network";
import { OrganizationsDAO } from "../../types/response.type";
import { useParams } from "react-router-dom";
import { Spinner, Heading } from "@chakra-ui/react";

const httpClient = getHttpClient();
export function OrganizationMain() {
  const { id } = useParams();
  const organization = useQuery<OrganizationsDAO>(
    [UseQueryKey.organizations, id],
    () => {
      return httpClient.get(`/organizations/${id}`).then((res) => res.data);
    }
  );
  if (organization.isLoading) {
    return <Spinner />;
  }
  if (!organization.data) {
    return null;
  }
  return (
    <div>
      <Heading>{organization.data.name}</Heading>
    </div>
  );
}
