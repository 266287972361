import { Header } from "../features/Header";
import { Main } from "../features/Main";
import { Sidebar } from "../features/Sidebar";
// import { useQuery } from "react-query";
// import { UseQueryKey } from "..//reactQuery.const";
// import { getHttpClient } from "../network";

export function FeedsPage() {
  // const feeds = useQuery([UseQueryKey.feeds], () => {
  //   return httpClient.get("/feeds").then((res) => res.data);
  // });
  return (
    <div>
      <Header />
      <div className="flex h-[calc(100vh-66px)]">
        <Sidebar />
        <Main />
      </div>
    </div>
  );
}
