import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { useCreateGroupModalStore } from "./store";
import { Form, Field } from "forming";
import { useAuth } from "../../hooks/useAuth";
import { useMutation } from "react-query";
import { getHttpClient } from "../../network";

const httpClient = getHttpClient();

export const CreateGroupModal = () => {
  const auth = useAuth();
  const store = useCreateGroupModalStore();
  const createGroupMut = useMutation((newGroup: any) => {
    return httpClient.post("/groups", newGroup).then((res) => res.data);
  });

  return (
    <Modal
      isOpen={store.isOpen}
      onClose={() => {
        createGroupMut.reset();
        store.toggle(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <Form
          validateOnSubmit
          onSubmit={({ values }) => {
            if (!auth.user || !auth.user.preferences?.activeOrgId) {
              return;
            }
            const payload = {
              ...values,
              orgId: auth.user.preferences.activeOrgId,
            };
            createGroupMut.mutate(payload);
          }}
        >
          <ModalHeader>Create A Channel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p className="text-gray-500 text-sm">
              Channels are where your team communicates. They’re best when
              organized around a topic — #marketing, for example.
            </p>
            <Field
              name="name"
              validate={{
                required: "Name is required",
              }}
            >
              {({ value = "", onChange, errors }) => {
                const validationError = errors && errors.length > 0;
                const httpError = !!createGroupMut.error;
                const hasErrors = validationError || httpError;
                let errorMsg = "";
                if (validationError) {
                  errorMsg = errors[0].message ?? "";
                } else if (httpError) {
                  const error = createGroupMut.error as any;
                  if (
                    error.response.data.error === "duplicate" &&
                    error.response.data.fields[0] === "name"
                  ) {
                    errorMsg = "Duplicate name";
                  }
                }
                return (
                  <FormControl isInvalid={hasErrors} className="mt-4">
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="email"
                      value={value}
                      onChange={(e) => {
                        createGroupMut.reset();
                        onChange(e);
                      }}
                    />
                    {!hasErrors ? (
                      <FormHelperText>
                        Enter the email you'd like to receive the newsletter on.
                      </FormHelperText>
                    ) : (
                      <FormErrorMessage>{errorMsg}</FormErrorMessage>
                    )}
                  </FormControl>
                );
              }}
            </Field>
            <Field name="description">
              {({ value = "", onChange, errors }) => {
                const hasErrors = errors && errors.length > 0;
                return (
                  <FormControl isInvalid={hasErrors} className="mt-2">
                    <FormLabel>
                      Description{" "}
                      <span className="text-sm text-gray-500">(optional)</span>
                    </FormLabel>
                    <Input type="text" value={value} onChange={onChange} />
                    {!hasErrors ? (
                      <FormHelperText>What's this channel about</FormHelperText>
                    ) : null}
                  </FormControl>
                );
              }}
            </Field>
            <Field name="private">
              {({ value = false, updateValue, errors }) => {
                const hasErrors = errors && errors.length > 0;
                return (
                  <FormControl isInvalid={hasErrors} className="mt-2">
                    <FormLabel>Make Private</FormLabel>
                    <Checkbox
                      isChecked={!!value}
                      onChange={(e) => {
                        updateValue(e.target.checked);
                      }}
                    >
                      Private
                    </Checkbox>
                    <FormHelperText>
                      When a channel is private, it can only be viewed or joined
                      by invitation
                    </FormHelperText>
                  </FormControl>
                );
              }}
            </Field>
          </ModalBody>

          <ModalFooter>
            <Button variant="solid" type="submit">
              Create
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};
