import { Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Icon } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { useEffect } from "react";

export function Header() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeOrgId = auth.user?.preferences?.activeOrgId;
  useEffect(() => {
    if (auth.user) {
      if (pathname === "/" && activeOrgId) {
        const activeOrg = auth.user.orgs.find(
          (org) => org.orgId === activeOrgId
        );
        if (activeOrg) {
          navigate(`/organizations/${activeOrg.org.symbol}`);
        }
      }
    }
  }, [pathname, activeOrgId, auth.user, navigate]);

  return (
    <div className="navbar bg-base-100">
      <div className="flex-1">
        {!auth.user || auth.user.orgs.length === 0 ? (
          <Link to="/" className="btn btn-ghost normal-case text-xl">
            QuickPost
          </Link>
        ) : (
          <Menu>
            <MenuButton as={Button} rightIcon={<Icon as={FiChevronDown} />}>
              {auth.user.orgs.find(
                (org) => org.orgId === auth.user.preferences?.activeOrgId
              )?.org.name ?? auth.user.orgs[0].org.name}
            </MenuButton>
            <MenuList>
              {auth.user.orgs.map((org) => (
                <MenuItem
                  key={org.org.symbol}
                  onClick={() => {
                    auth.updatePreferences({ activeOrgId: org.orgId });
                    navigate(`/organizations/${org.org.symbol}`);
                  }}
                >
                  {org.org.name}
                </MenuItem>
              ))}
              <MenuItem key="new">Add new org</MenuItem>
            </MenuList>
          </Menu>
        )}
      </div>
      <div className="flex-1"></div>
      <Link to="/organizations">Orgs</Link>
      <div className="flex-none gap-2">
        <div className="form-control">
          <input
            type="text"
            placeholder="Search"
            className="input input-bordered"
          />
        </div>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img src="https://placeimg.com/80/80/people" />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
          >
            <li>
              <a className="justify-between">
                Profile
                <span className="badge">New</span>
              </a>
            </li>
            <li>
              <a>Settings</a>
            </li>
            <li>
              <Button
                colorScheme="teal"
                variant="link"
                onClick={async () => {
                  await auth.logout();
                  navigate("/login");
                }}
              >
                Logout
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
